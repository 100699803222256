.hull {
    fill-opacity: 0.7;
}

.hull:hover {
    fill-opacity: 1;
}

.hull-highlighted {
    stroke-opacity: 1;
    stroke-width: 50;
    stroke: black;
}

.hull-secondary-highlighted {
    stroke-width: 80;
    stroke: black;
    stroke-dasharray: 30;
}

@keyframes blinking {
  0% {
      fill-opacity: 0;
  }

  50% {
      fill-opacity: 1;
  }

  100% {
      fill-opacity: 0;
  }
}

.hull-removing {
    fill: lightgray;
    stroke: gray;
    stroke-width: 5;
}

.mNum, .meterSig {
    display: none;
}
